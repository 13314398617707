<template>
	<div class="home-slider">
		<div class="app-container app-container-fluid home-slider__container">
			<div class='home-slider__scroll mobile-hide'>
				<div class="row align-items-stretch">
					<div class="col-auto"
						v-for='(item, i) in items'
						:key='i'
					>
						<div class="home-slider__content">
							<p class='home-slider__content--title'>{{item.title}}</p>
							<v-icon :icon='`sliders/${item.icon}`' size='68' class='home-slider__content--icon' />
							<p class='home-slider__content--text'>{{item.text}}</p>
						</div>
					</div>
				</div>
			</div>

			<carousel class='mobile-show'
				:perPage='1'
				:paginationSize='9'
				:paginationPadding='4'
				paginationColor='#ccf1ea'
				paginationActiveColor='#00B796'
			>
				<slide v-for='(item, i) in items' :key='i'>
					<div class="home-slider__content">
						<p class='home-slider__content--title'>{{item.title}}</p>
						<v-icon :icon='`sliders/${item.icon}`' size='68' class='home-slider__content--icon' />
						<p class='home-slider__content--text'>{{item.text}}</p>
					</div>
				</slide>
			</carousel>
		</div>
	</div>
</template>

<script>
	import { Carousel, Slide } from 'vue-carousel';

	export default {
		data: () => ({
			pos: { top: 0, left: 0, x: 0, y: 0 },
			element: null
		}),
		props: ["items"],
		components: {
			Carousel,
			Slide
		},
		mounted() {
			this.element = document.querySelector('.home-slider__scroll');
			this.element.style.cursor = 'grab';
			this.element.addEventListener('mousedown', this.mouseDownHandler);
		},
		methods: {
			mouseDownHandler (e) {
				this.element.style.cursor = 'grabbing';
				this.element.style.userSelect = 'none';

				this.pos = {
					left: this.element.scrollLeft,
					top: this.element.scrollTop,
					x: e.clientX,
					y: e.clientY,
				};

				document.addEventListener('mousemove', this.mouseMoveHandler);
				document.addEventListener('mouseup', this.mouseUpHandler);
			},
			mouseMoveHandler (e) {
				const dx = e.clientX - this.pos.x;
				const dy = e.clientY - this.pos.y;
				this.element.scrollTop = this.pos.top - dy;
				this.element.scrollLeft = this.pos.left - dx;
			},
			mouseUpHandler () {
				this.element.style.cursor = 'grab';
				this.element.style.removeProperty('user-select');

				document.removeEventListener('mousemove', this.mouseMoveHandler);
				document.removeEventListener('mouseup', this.mouseUpHandler);
			}
		}
	}
</script>

<style lang="scss">
	@import '@/styles/global/variables';
	@import '@/styles/global/mixins';

	.home-slider {
		overflow: hidden;
		&__container {
			padding: 48px 0 56px;
			@include minw( $grid-breakpoints-xs ) {
				padding: 134px * .6 0 120px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 134px * .8 0 120px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 134px 0 120px;
			}
			.row {
				flex-wrap: nowrap;
				padding-left: 10px;
				padding-bottom: 72px * .6;
				@include minw( $grid-breakpoints-xs ) {
					padding-left: 20px;
				}
				@include minw( $grid-breakpoints-sm ) {
					padding-left: 120px * .6;
					padding-bottom: 72px * .6;
				}
				@include minw( $grid-breakpoints-md ) {
					padding-left: 120px * .8;
					padding-bottom: 72px * .8;
				}
				@include minw( $grid-breakpoints-lg ) {
					padding-left: 120px;
					padding-bottom: 72px;
				}
			}
		}
		&__scroll {
			overflow-y: hidden;
			overflow-x: auto;
			&::-webkit-scrollbar-track {
				margin-left: 20px;
				margin-right: 20px;
				border-bottom: 1px solid rgba($black, .2);
				@include minw( $grid-breakpoints-sm ) {
					margin-left: 120px * .6;
					margin-right: 100px * .6;
				}
				@include minw( $grid-breakpoints-md ) {
					margin-left: 120px * .8;
					margin-right: 100px * .8;
				}
				@include minw( $grid-breakpoints-lg ) {
					margin-left: 120px;
					margin-right: 100px;
				}
			}
		}
		&__content {
			height: 100%;
			padding: 24px;
			border: 1px solid rgba($black, .2);
			@include minw( $grid-breakpoints-xs ) {
				width: 336px;
			}
			&--title {
				font-size: 20px;
				font-weight: 700;
				line-height: 1.4;
				text-transform: uppercase;
				margin-bottom: 44px;
				@include minw( $grid-breakpoints-xs ) {
					min-height: 84px;
				}
			}
			&--icon {
				display: inline-flex;
				width: auto !important;
				max-width: 100%;
				margin-bottom: 18px;
			}
			&--text {
				line-height: 1.4;
				max-width: 246px;
				margin-bottom: 8px;
			}
		}
		.VueCarousel {
			&-pagination {
				margin-top: 12px;
			}
			.home-slider__content {
				width: calc(100% - 20px);
				margin: 0 10px;
			}
		}
	}
</style>
