<template>
	<div class="home-carousel">
		<div class="home-carousel__container">
			<div class="home-carousel__list">
				<div class="home-carousel__item" v-for='(item, i) in items' :key='`init-${i}`'>
					<span
						class="home-carousel__icon"
						v-html="require(`!html-loader!@/assets/logos/${initPath}${item}.svg`)"
					/>
				</div>
				<div class="home-carousel__item" v-for='(item, i) in items' :key='`double-${i}`'>
					<span
						class="home-carousel__icon"
						v-html="require(`!html-loader!@/assets/logos/${initPath}${item}.svg`)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			initPath: String,
			items: Array
		}
	}
</script>

<style lang="scss">
	@import '@/styles/global/variables';
	@import '@/styles/global/mixins';

	.home-carousel {
		display: grid;
		overflow: hidden;
		&__list {
			display: flex;
			align-items: center;
			animation: translateCarousel 50s linear infinite;
			will-change: left;
			&:hover {
				animation-play-state: paused;
			}
		}
		&__item {
			height: 50px;
			margin: 0 24px;
		}
		&__icon svg {
			width: auto;
		}

		&__hotels {
			.home-carousel__container {
				padding-top: 48px;
				@include minw( $grid-breakpoints-xs ) {
					padding-top: 134px * .6;
				}
				@include minw( $grid-breakpoints-md ) {
					padding-top: 134px * .8;
				}
				@include minw( $grid-breakpoints-lg ) {
					padding-top: 134px;
				}
			}
			.home-carousel__list {
				animation-name: translateCarouselReverse;
			}
			.home-carousel__item {
				height: 25px;
			}
		}
		&__avia {
			.home-carousel__container {
				padding-bottom: 56px;
				@include minw( $grid-breakpoints-xs ) {
					padding-bottom: 120px * .6;
				}
				@include minw( $grid-breakpoints-md ) {
					padding-bottom: 120px * .8;
				}
				@include minw( $grid-breakpoints-lg ) {
					padding-bottom: 120px;
				}
			}
		}
	}

	@keyframes translateCarousel {
		from { left: 0%; }
		to { left: -50%; }
	}
	@keyframes translateCarouselReverse {
		from { left: -50%; }
		to { left: 0%; }
	}
</style>
