<template>
	<div>
		<index-header />
		<carousel-logos initPath='hotel/' :items='items_hotels' class='home-carousel__hotels' />
		<index-slider :items="$t('main_content.index.advantages')" />
		<carousel-logos initPath='avia/' :items='items_avia' class='home-carousel__avia' />
	</div>
</template>

<script>
	import IndexHeader from '@/views/main/components/IndexHeader';
	import IndexSlider from '@/views/main/components/IndexSlider';
	import CarouselLogos from '@/views/main/components/CarouselLogos';

	export default {
		data: () => ({
			items_hotels: ["AMR", "anantara", "angsana", "banyantree", "fourseasons", "h10hotels", "hilton", "hyatt", "iberostar", "ihg", "lhw", "majestic-resorts", "marriott", "melia", "oneandonlyresorts", "palladiumhotelgroup", "riu", "sandals", "slh", "theexcellencecollection"],
			items_avia: ["intro-car-logo-A3", "intro-car-logo-AA", "intro-car-logo-AF", "intro-car-logo-BA", "intro-car-logo-CSA", "intro-car-logo-EK", "intro-car-logo-IB", "intro-car-logo-KL", "intro-car-logo-LH", "intro-car-logo-LX", "intro-car-logo-OA", "intro-car-logo-OS", "intro-car-logo-QR", "intro-car-logo-SN", "intro-car-logo-SQ", "intro-car-logo-UA", "intro-car-logo-VY", "intro-car-logo-xUIA", "skyup", "bees", "airarabia", "flydubai", "wizz", "ryanair", "windrose"
				// "intro-car-logo-A9",
				// "intro-car-logo-AY",
				// "intro-car-logo-DY",
				// "intro-car-logo-eva",
				// "intro-car-logo-QS",
				// "intro-car-logo-RM",
				// "intro-car-logo-VK",
			]
		}),
		components: {
			IndexHeader,
			IndexSlider,
			CarouselLogos
		}
	}
</script>
